import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import {
    Menu,
    Form,
    Input,
    Button,
    Pagination,
    DatePicker,
    Tooltip,
    Modal,
    Drawer
} from 'ant-design-vue';

const app = createApp(App)
if (process.env.VUE_APP_NODE_ENV === 'production') {
    app.config.devtools = false
    app.config.productionTip = false
}
app.use(Modal)
app.use(Menu)
app.use(Form)
app.use(Input)
app.use(Button)
app.use(Pagination)
app.use(DatePicker)
app.use(Tooltip)
app.use(Drawer)
app.use(store)
app.use(router)
app.mount('#photolure')
