<template>
    <div class="page-wrapper page-wrapper-full">
        <div class="page-info">
            <h1>Search results for: {{ state.SearchBy.text || state.SearchBy.date || state.SearchBy.year }}</h1>
            <template v-if="!state.PhotoList.length">
                <div class="page-paragraph">
                    Your search returned no results.
                </div>
            </template>
        </div>
        <div class="photo-list">
            <template v-for="(item,index) in state.PhotoList" :key="index">
                <PhotoItem :PhotoItem="item" :modal="true" :PhotoIndex="index"/>
            </template>
        </div>
        <PaginationComponent
            :itemCount="state.itemCount"
            :listLength="state.PhotoList.length"
            :maxPageCount="state.pageCount"
            :currentPage="state.currentPage"
            :perPage="state.perPage"
            componentName="search"
            :resetCurrentPage="state.resetCurrentPageValue"
        />
    </div>
    <PhotoModal/>
</template>

<script setup>
import {onMounted, onUnmounted, reactive, watch} from "vue";
import {ErrorHandler, errorMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import PhotoItem from "@/components/partials/PhotoItem.vue";
import PhotoModal from "@/components/partials/PhotoModal.vue";
import store from "@/store";
import PaginationComponent from "@/components/partials/PaginationComponent.vue";
import router from "@/router";
import dayjs from "dayjs";
import {SetMetaTags} from "@/helpers/setMetaTags";

const state = reactive({
    PhotoList: [],
    currentPage: 1,
    itemCount: null,
    pageCount: null,
    perPage: 50,
    resetCurrentPageValue: 1,
    SearchBy: {
        text: '',
        date: ''
    },
})

onMounted(async () => {
    if (!checkUrlForQuery()) return router.replace('/')
    const {date, text, page} = checkUrlForQuery()
    ToggleLoader(true, false)
    if (page) state.currentPage = Number(page)
    if (text) {
        store.commit('Search/setSearchText', text)
        store.commit('Search/clearSearchData', {type: 'date'})
    }
    if (date) {
        store.commit('Search/setSearchDate', date)
        store.commit('Search/clearSearchData', {type: 'text'})
    }
    state.SearchBy = {date, text}
    await init()
})
onUnmounted(() => {
    window.scrollTo(0, 0);
    store.commit('Search/setSearchDate', '')
    store.commit('Search/setSearchText', '')
    store.commit('PhotoModal/setPhotoList', [])
    state.PhotoList = []
})
watch(router.currentRoute, async data => {
    if (data.query) {
        if (!checkUrlForQuery()) return router.replace('/')
        const {date, text, page} = checkUrlForQuery()
        if (text && text !== state.SearchBy.text) {
            ToggleLoader(true,true)
            store.commit('Search/setSearchText', text)
            store.commit('Search/clearSearchData', {type: 'date'})
            state.SearchBy.date = ''
            state.SearchBy.text = text
            state.currentPage = 1
            state.resetCurrentPageValue += 1
            await init()
        }
        if (date && date !== state.SearchBy.date) {
            ToggleLoader(true,true)
            store.commit('Search/setSearchDate', date)
            store.commit('Search/clearSearchData', {type: 'text'})
            state.SearchBy.date = date
            state.SearchBy.text = ''
            state.currentPage = 1
            state.resetCurrentPageValue += 1
            await init()
        }
        if (data.query.page && Number(data.query.page) !== Number(state.currentPage)) {
            ToggleLoader(true,true)
            state.currentPage = Number(page)
            data.query.page ? state.currentPage = Number(data.query.page) : 1
            await init()
        }
    }
})
const checkUrlForQuery = () => {
    let query = router.currentRoute.value.query
    let {date, text, year, page} = query
    if (!date && !text && !year) return router.replace('/')
    return {date, text, year, page}
}
const init = async () => {
    const PhotoList = await getPhotographerImages(state.perPage, state.currentPage)
    if (PhotoList?.data && PhotoList?.status === 200) {
        state.PhotoList = PhotoList?.data
        state.itemCount = PhotoList?.meta.itemCount
        state.pageCount = PhotoList?.meta.pageCount
        SetMetaTags(null, window.location.href, PhotoList?.metaData)
        store.commit('PhotoModal/setPhotoList', PhotoList?.data)
        window.scrollTo(0, 0);
    }

    ToggleLoader(false, false)
}
const getPhotographerImages = async (take = 0, page = 1) => {
    let string = ''
    if (state.SearchBy.text) {
        string += `&filter=${state.SearchBy.text}`
    }
    if (state.SearchBy.date) {
        string += '&date=' + dayjs(state.SearchBy.date).format('YYYY-MM-DD')
    }

    let Url = `/photos?page=${page}&take=${take}${string}`

    try {
        const res = await http.get(Url)
        return {
            data: res.data.data,
            meta: res.data.meta,
            metaData: res.data.metadata,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}
</script>
