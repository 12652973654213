<template>
    <div class="page-wrapper page-wrapper-full">
        <div class="page-info">
            <div class="date">{{ moment(state.photoSetData.shoot_date).format('dddd, MMMM DD YYYY') }}</div>
            <h1>{{ state.photoSetData.title }}</h1>
        </div>
        <div class="photo-list">
            <template v-for="(item,index) in state.photoList" :key="index">
                <PhotoItem :PhotoItem="item" :modal="true" :PhotoIndex="index"/>
            </template>
        </div>
    </div>
    <PhotoModal/>
</template>

<script setup>
import {computed, onMounted, onUnmounted, reactive, watch} from "vue";
import {ToggleLoader} from "@/helpers";
import http from "@/http";
import PhotoItem from "@/components/partials/PhotoItem.vue";
import {useRoute} from "vue-router";
import moment from "moment";
import route from "@/router"
import PhotoModal from "@/components/partials/PhotoModal.vue";
import store from "@/store";
import {SetMetaTags} from "@/helpers/setMetaTags";

const state = reactive({
    photoList: [],
    photoSetData: {}
})

const router = useRoute()
const PhotoSetID = computed(() => router.params.PhotoSetID)

onMounted(async () => {
    const PhotoSetData = await getPhotoSetData()
    if (PhotoSetData?.data && PhotoSetData?.status === 200) {
        SetMetaTags(null, window.location.href, PhotoSetData?.metaData)
        state.photoSetData = PhotoSetData?.data
        state.photoList = PhotoSetData?.data.images
        store.commit('PhotoModal/setPhotoList', PhotoSetData?.data.images)
    }
    ToggleLoader(false, false)
})
watch(() => PhotoSetID.value, async data => {
    if(data) {
        window.scrollTo(0,0)
        const PhotoSetData = await getPhotoSetData()
        if (PhotoSetData?.data && PhotoSetData?.status === 200) {
            SetMetaTags(null, window.location.href, PhotoSetData?.metaData)
            state.photoSetData = PhotoSetData?.data
            state.photoList = PhotoSetData?.data.images
            store.commit('PhotoModal/setPhotoList', PhotoSetData?.data.images)
        }
        ToggleLoader(false, false)
    }
})
onUnmounted(() => {
    store.commit('PhotoModal/setPhotoList', [])
    state.photoSetData = {}
    state.photoList = []
})

const getPhotoSetData = async () => {
    ToggleLoader(true, false)
    try {
        const res = await http.get(`/collections/${PhotoSetID.value}`)
        return {
            data: res.data.data,
            metaData: res.data.metadata,
            status: res.status
        }
    } catch (error) {
        return route.replace('/photoset')
    }
}

</script>
