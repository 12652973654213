/* eslint-disable no-template-curly-in-string */

export function ErrorHandler(error) {
    let errorMassage = "Something went wrong. Please try again!"
    if (!error) return errorMassage
    if (error.response && error.response.status) {
        const statusCode = error.response.status,
            errorData = error.response.data
        if (statusCode >= 400 && statusCode <= 500)
            return errorData.message ? errorData.message : errorMassage
    } else if (error.request) {
        const {readyState, status} = error.request
        if (readyState === 4 && status === 0) {
            return "Unknown Error Occurred. Server response not received."
        }
    }
}
