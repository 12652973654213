<template>
    <div class="page-wrapper page-wrapper-full">
        <div class="slider-box">
            <template v-for="(item,index) in state.SliderData" :key="index">
                <template v-if="item.type === 'EDITOR_CHOICE'">
                    <div class="slider">
                        <div class="label">EDITOR'S CHOICE</div>

                        <carousel :items-to-show="2" :autoplay="3000" :pauseAutoplayOnHover="true" :wrap-around="true" :breakpoints="breakpoints">
                            <slide v-for="(image,imageIndex) in item.images" :key="imageIndex">
                                <router-link class="carousel__item" :to="'/photoset/' + image.id">
                                    <PictureComponent
                                        :url="image.image"
                                        :height="image.image_height"
                                        :width="image.image_width"
                                        :alt="image.description"
                                        :imageCode="item.image_code"
                                        :loader="true"
                                    />
                                    <div class="info-box">
                                        <div class="name" v-text="image.description"/>
                                        <div class="date"
                                             v-text="moment(image.shoot_date).format('dddd, MMMM DD YYYY')"/>
                                    </div>
                                </router-link>
                            </slide>
                            <template #addons>
                                <Navigation class="carousel__nav-button"/>
                            </template>
                        </carousel>
                    </div>
                </template>
                <template v-if="item.type === 'HIGHLIGHTS'">
                    <div class="highlights">
                        <div class="label">HIGHLIGHTS</div>
                        <router-link class="carousel__item" :to="'/photoset/' + item.id">
                            <PictureComponent
                                :url="item.image"
                                :height="item.image_height"
                                :width="item.image_width"
                                :alt="item.description"
                                :imageCode="item.id.toString()"
                            />
                            <div class="info-box">
                                <div class="name" v-text="item.description"/>
                                <div class="date" v-text="moment(item.shoot_date).format('dddd, MMMM DD YYYY')"/>
                            </div>
                        </router-link>
                    </div>
                </template>
            </template>
        </div>
        <div class="photo-list">
            <template v-for="(item,index) in state.list" :key="index">
                <PhotoItem :PhotoItem="item"/>
            </template>
        </div>
        <PaginationComponent
            :itemCount="state.itemCount"
            :listLength="state.list.length"
            :maxPageCount="state.pageCount"
            :currentPage="state.currentPage"
            :perPage="state.perPage"
            componentName="home"
            :resetCurrentPage="state.resetCurrentPageValue"
        />
    </div>
</template>

<script setup>
import {onMounted, reactive, watch} from "vue";
import {ErrorHandler, errorMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import PhotoItem from "@/components/partials/PhotoItem.vue";
import PaginationComponent from "@/components/partials/PaginationComponent.vue";
import {SetMetaTags} from "@/helpers/setMetaTags";
import PictureComponent from "@/components/partials/PictureComponent.vue";
import {Carousel, Slide, Navigation} from 'vue3-carousel'
import moment from "moment";
import router from "@/router";

const breakpoints = {
    0: {
        itemsToShow: 1,
    },
    1024: {
        itemsToShow: 2,
    }
}

const state = reactive({
    currentPage: 1,
    itemCount: null,
    pageCount: null,
    list: [],
    perPage: 50,
    SliderData: [],
    resetCurrentPageValue: 1
})

onMounted(async () => {
    const {page} = checkUrlForQuery()
    if (page) state.currentPage = Number(page)
    ToggleLoader(true, false)
    SetMetaTags('/', window.location.href)
    const PhotoSet = await getPhotoSets(state.perPage, state.currentPage)
    const SliderSet = await getSliderData()
    if (PhotoSet && PhotoSet.status === 200 && PhotoSet.data) {
        state.list = [...PhotoSet.data]
        state.itemCount = PhotoSet.meta.itemCount
        state.pageCount = PhotoSet.meta.pageCount
        if (SliderSet && SliderSet.status === 200 && SliderSet.data) {
            state.SliderData = SliderSet.data
            ToggleLoader(false)
        }
    }
})
watch(router.currentRoute, async data => {
    if(data.path === '/' && !data.query.page) {
        state.currentPage = 1
        state.resetCurrentPageValue += 1
    }
    if (data.query.page || (data.path === '/' && !data.query.page)) {
        ToggleLoader(true, true)
        SetMetaTags('/', window.location.href)
        data.query.page ? state.currentPage = Number(data.query.page) : 1
        const PhotoSet = await getPhotoSets(state.perPage, state.currentPage)
        if (PhotoSet && PhotoSet.status === 200 && PhotoSet.data) {
            state.list = [...PhotoSet.data]
            state.itemCount = PhotoSet.meta.itemCount
            state.pageCount = PhotoSet.meta.pageCount
            window.scrollTo(0, 0);
            ToggleLoader(false)
        }
    }
})
const checkUrlForQuery = () => {
    let query = router.currentRoute.value.query
    let {page} = query
    if (!page) return router.replace('/')
    return {page}
}
const getSliderData = async () => {
    try {
        const res = await http.get('/slider')
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}

const getPhotoSets = async (take = 0, page = 1) => {
    let Url = `/collections?page=${page}&take=${take}`
    try {
        const res = await http.get(Url)
        return {
            data: res.data.data,
            meta: res.data.meta,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}
</script>
