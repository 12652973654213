<template>
    <div class="image-box">
        <picture>
            <source
                loading="lazy"
                type="image/webp"
                :srcset="UrlCreator(props.url, 'webp')"
            >
            <source
                loading="lazy"
                type="image/jpg"
                :srcset="UrlCreator(props.url, 'jpg')"
            >
            <img
                ref="ImageRef"
                :title="title"
                loading="lazy"
                :src="UrlCreator(props.url, 'jpg')"
                :alt="alt"
                :width="width"
                :height="height"
                :id="imageCode"
            />
        </picture>
    </div>
</template>

<script setup>
import store from "@/store";
import {computed, ref, watch} from "vue";

const ImageRef = ref()
const props = defineProps({
    url: String,
    width: Number,
    height: Number,
    title: String,
    alt: String,
    loader: {
        type: Boolean,
        default: false
    },
    imageCode: {
        type: String,
        default: ''
    }
})
const CdnUrl = computed(() => {
    return store.getters["Config/GetCdnUrl"]
})

const UrlCreator = (url, type) => {
    if (!url) return
    return CdnUrl.value + url.split('.').slice(0, -1).join('.') + '.' + type
}

watch(() => ImageRef.value, img => {
    if (props.loader && img) {
        img.style.height = 'auto'
    }
})
</script>
