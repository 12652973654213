<template>
    <template v-if="!modal">
        <router-link
            :to="'/photoset/' + PhotoItem.id"
            class="photo-item"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
        >
            <div class="photo-item-wrap">
                <PictureComponent
                    :height="PhotoItem.thumbnail_height"
                    :width="PhotoItem.thumbnail_width"
                    :alt="PhotoItem.description"
                    :url="PhotoItem.thumbnail"
                    :title="PhotoItem.title ? PhotoItem.title : PhotoItem.description"
                />
                <div class="date">
                    {{ moment(PhotoItem.shoot_date).format('dddd, MMMM DD YYYY') }}
                </div>
                <div class="image-code" v-if="PhotoItem.image_code">
                    <span>code</span> <span>{{ PhotoItem.image_code }}</span>
                </div>
                <div class="title">{{ PhotoItem.title ? PhotoItem.title : PhotoItem.description }}</div>
            </div>
        </router-link>
    </template>
    <template v-else>
        <div
            class="photo-item"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
            @click="handlePhotoClick(PhotoIndex)"
        >
            <div
                class="photo-item-wrap"
            >
                <PictureComponent
                    :url="PhotoItem.thumbnail"
                    :height="PhotoItem.thumbnail_height"
                    :width="PhotoItem.thumbnail_width"
                    :title="PhotoItem.title ? PhotoItem.title : PhotoItem.description"
                    :alt="PhotoItem.description"
                />
                <div class="date">
                    {{ moment(PhotoItem.shoot_date).format('dddd, MMMM DD YYYY') }}
                </div>
                <div class="image-code" v-if="PhotoItem.image_code">
                    <span>code</span> <span>{{ PhotoItem.image_code }}</span>
                </div>
                <div class="title">{{ PhotoItem.title ? PhotoItem.title : PhotoItem.description }}</div>
            </div>
        </div>
    </template>
</template>

<script setup>
import {isMobile} from 'mobile-device-detect';
import PictureComponent from "@/components/partials/PictureComponent.vue";
import moment from "moment";
import store from "@/store";

defineProps({
    PhotoItem: Object,
    PhotoIndex: Number,
    modal: {
        type: Boolean,
        default: false
    }
})
const handleMouseEnter = (e) => {
    if (isMobile) return
    const child = e.target.children[0]
    const {width,height} = e.target.getBoundingClientRect()
    const subChildLastIndexOF = e.target.children[0].children.length - 1
    e.target.style.width = width + 'px'
    e.target.style.height = height + 'px'
    child.style.position = 'absolute'
    child.children[subChildLastIndexOF].style['-webkit-line-clamp'] = 'inherit'
}
const handleMouseLeave = (e) => {
    if (isMobile) return
    const child = e.target.children[0]
    const subChildLastIndexOF = e.target.children[0].children.length - 1
    e.target.style.width = ''
    e.target.style.height = ''
    child.style.position = ''
    child.children[subChildLastIndexOF].style['-webkit-line-clamp'] = '2'
}

const handlePhotoClick = (PhotoIndex) => {
    store.commit('PhotoModal/setActiveIndex', PhotoIndex)
}
</script>
