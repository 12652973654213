/* eslint-disable no-template-curly-in-string */
import {createStore} from 'vuex'

const store = createStore({
    state: {
        Loader: {
            show: false,
            transparent: false
        }
    },
    modules: {
        Search: {
            namespaced: true,
            state() {
                return {
                    text: '',
                    date: ''
                }
            },
            getters: {
                GetSearchText(state) {
                    return state.text
                },
                GetSearchDate(state) {
                    return state.date
                }
            },
            mutations: {
                setSearchText(state, data) {
                    state.text = data
                },
                setSearchDate(state, data) {
                    state.date = data
                },
                clearSearchData(state, data) {
                    const {type} = data
                    if (!type) {
                        state.text = ''
                        state.date = ''
                    } else {
                        state[type] = ''
                    }
                }
            }
        },
        Config: {
            namespaced: true,
            state() {
                return {
                    pages: [],
                    years: [],
                    CDN_URL: ''
                }
            },
            getters: {
                GetYearsList(state) {
                    return state.years
                },
                GetPageData(state) {
                    return state.pages
                },
                GetCdnUrl(state) {
                    return state.CDN_URL
                }
            },
            mutations: {
                setYearsList(state, data) {
                    state.years = [...data]
                },
                setPageConfig(state, data) {
                    state.pages = [...data]
                },
                setCdnUrl(state, data) {
                    state.CDN_URL = data
                }
            }
        },
        PhotoModal: {
            namespaced: true,
            state() {
                return {
                    activeIndex: null,
                    photoList: [],
                    ModalVisible: false,
                    showLoader: false,
                }
            },
            getters: {
                GetPhotoInfo(state) {
                    return state.photoList[state.activeIndex]
                },
            },
            mutations: {
                setPhotoList(state, data) {
                    state.photoList = [...data]
                    if (!data.length) {
                        state.ModalVisible = false
                    }
                },
                setActiveIndex(state, data) {
                    state.activeIndex = data
                },
                toggleModalLoader(state, data) {
                    state.showLoader = data
                },
                setModalVisible(state, data) {
                    state.ModalVisible = data
                }
            }
        }
    },
    mutations: {
        SHOW_LOADER(state, data) {
            state.Loader = data
        }
    },
    actions: {
        LoaderAction({commit}, payload) {
            commit('SHOW_LOADER', payload)
        }
    }
})
export default store
