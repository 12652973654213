<template>
    <div class="photo-list-nav" v-if="itemCount > listLength">
        <div
            class="photo-list-nav-item prev"
            :class="state.currentPage === 1?'not-allowed':''"
            @click="handlePageChange('prev')"
        >
                <span>
                    Prev<br/>Page
                </span>
            <PaginationIcons type="left"/>
        </div>
        <div
            class="photo-list-nav-item next"
            :class="state.currentPage === maxPageCount?'not-allowed':''"
            @click="handlePageChange('next')"
        >
                <span>
                    Next<br/>Page
                </span>
            <PaginationIcons type="right"/>
        </div>
    </div>
</template>

<script setup>
import PaginationIcons from "@/assets/icons/PaginationIcons.vue";
import {computed, reactive, watch} from "vue";
import {useRoute} from "vue-router";
import router from "@/router";

const props = defineProps({
    itemCount: Number,
    listLength: Number,
    maxPageCount: Number,
    currentPage: Number,
    perPage: Number,
    resetCurrentPage: Number,
    componentName: {
        type: String,
        default: ''
    }
})
const state = reactive({
    currentPage: props.currentPage
})
watch(() => props.currentPage, data => {
    state.currentPage = data
})
watch(() => props.resetCurrentPage, data => {
    if (data) {
        state.currentPage = 1
    }
})
const route = useRoute()
const Year = computed(() => route.params.Year)
watch(Year, async data => {
    if (data) return state.currentPage = 1
})
const handlePageChange = async (event) => {
    let pageNumber = state.currentPage
    const {text, date} = router.currentRoute.value.query
    let queryParams = {}
    if (text) {
        queryParams.text = router.currentRoute.value.query.text
    }
    if (date) {
        queryParams.date = router.currentRoute.value.query.date
    }
    if (event === 'next' && pageNumber !== props.maxPageCount) {
        pageNumber += 1
        queryParams.page = pageNumber
        await router.push({name: props.componentName, query: queryParams})
    }
    if (event === 'prev' && pageNumber !== 1) {
        pageNumber -= 1
        queryParams.page = pageNumber
        await router.push({name: props.componentName, query: queryParams})
    }
    state.currentPage = pageNumber
}
</script>
