<template>
    <div class="page-wrapper">
        <h1>{{ state.page_title }}</h1>
        <div class="page-paragraph" v-html="state.page_content.replace(/\n/g, '<br/>')"/>

    </div>
</template>

<script setup>

import {computed, onMounted, reactive} from "vue";
import {SetMetaTags} from "@/helpers/setMetaTags";
import store from "@/store";

const state = reactive({
    page_title: '',
    page_content: ''
})

const pagesData = computed(() => {
    return store.getters["Config/GetPageData"]
})

onMounted(() => {
    SetMetaTags('/about-us', window.location.href)
    state.page_content = pagesData.value.filter(item => item.slug === '/about-us')[0].page_content
    state.page_title = pagesData.value.filter(item => item.slug === '/about-us')[0].page_title
})
</script>
