import {message} from "ant-design-vue";

message.config({
    maxCount: 1,
})

export function successMessage(text, duration = 2) {
    return new Promise((resolve, reject) => {
        message.success(text, duration).then(afterClose => {
            afterClose ? resolve({'message': text, 'success': true}) : reject(Error("Success Message Rejected"));
        });
    });
}

export function errorMessage(text, duration = 2) {
    return new Promise((resolve, reject) => {
        message.error(typeof text === "string" ? text : 'Back Side Error can\'t display!', duration).then(afterClose => {
            afterClose ? resolve({'message': text, 'success': false}) : reject(Error("Error Message Rejected"));
        });
    });
}

export function warningMessage(text, duration = 2) {
    return new Promise((resolve, reject) => {
        message.warning(text, duration).then(afterClose => {
            afterClose ? resolve({'message': text, 'success': true}) : reject(Error("Success Message Rejected"));
        });
    });
}
