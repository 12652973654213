<template>
    <div class="page-wrapper page-contact">
        <h1>{{ state.page_title }}</h1>

        <div class="flex-box">
            <a-form
                :model="formState"
                name="photo_lure_contact"
                autocomplete="off"
                :validate-messages="validateMessages"
                @finish="Submit"
            >
                <div class="info">
                    <template v-for="(item,index) in state.page_content" :key="index">
                        <div class="info-item">
                            <span>{{ item.label }}: </span>
                            <template v-if="item.type === 'text'">
                                {{ item.value }}
                            </template>
                            <template v-if="item.type === 'tel'">
                                <a :href="'tel:'+ item.value">{{ item.value }}</a>
                            </template>
                            <template v-if="item.type === 'email'">
                                <a :href="'mailto:'+ item.value">{{ item.value }}</a>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="field-row half">
                    <a-form-item
                        label="Your e-mail"
                        name="email"
                        :rules="[{ required: true,type: 'email'}]"
                    >
                        <a-input v-model:value="formState.email" placeholder="Your e-mail"/>
                    </a-form-item>
                </div>

                <div class="field-row">
                    <a-form-item
                        label="Subject"
                        name="subject"
                        :rules="[{ required: true}]"
                    >
                        <a-input v-model:value="formState.subject" placeholder="Subject"/>
                    </a-form-item>
                </div>

                <div class="field-row">
                    <a-form-item
                        label="Message"
                        name="message"
                        :rules="[{ required: true}]"
                    >
                        <a-textarea :rows="6" v-model:value="formState.message" placeholder="Message"/>
                    </a-form-item>
                </div>

                <a-button html-type="submit">Send</a-button>
            </a-form>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d978.069706859836!2d44.5156348268648!3d40.17993359340124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abcfb2066e23b%3A0xfe0c63e88d9e0b50!2s3a%20Pushkin%20St%2C%20Yerevan%200010%2C%20Armenia!5e0!3m2!1sen!2s!4v1675078287705!5m2!1sen!2s"
                style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script setup>
import validateMessages from "@/helpers/validateMessages";
import {computed, onMounted, reactive} from "vue";
import http from "@/http";
import {successMessage, ToggleLoader} from "@/helpers";
import {SetMetaTags} from "@/helpers/setMetaTags";
import store from "@/store";

const formState = reactive({
    flag: true,
    message: '',
    subject: '',
    email: ''
})

const state = reactive({
    page_title: '',
    page_content: ''
})
const pagesData = computed(() => {
    return store.getters["Config/GetPageData"]
})
onMounted(() => {
    window.scrollTo(0, 0);
    SetMetaTags('/contact', window.location.href)
    state.page_content = pagesData.value.filter(item => item.slug === '/contact')[0].page_content
    state.page_title = pagesData.value.filter(item => item.slug === '/contact')[0].page_title
})
const Submit = async (values) => {
    if (!formState.flag) return
    ToggleLoader(true, true)
    formState.flag = false
    try {
        await http.post('/contact', values)
        ToggleLoader(false, true)
        formState.message = ''
        formState.subject = ''
        formState.email = ''
        formState.flag = true
        return successMessage('Message received successfully!')
    } catch (error) {
        return ToggleLoader(false, true)
    }
}
</script>
