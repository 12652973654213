<template>
    <LoaderComponent v-if="LoaderState.show" :transparent="LoaderState.transparent"/>
    <LoaderComponent v-if="state.loader"/>
    <HeaderComponent/>
    <div class="site-container" v-if="state.mount">
        <router-view/>
    </div>
    <FooterComponent/>
</template>

<script setup>
import './scss/style.scss'
import HeaderComponent from "@/components/layout/HeaderComponent";
import LoaderComponent from "@/components/partials/LoaderComponent";
import {computed, onMounted, reactive} from "vue";
import {useStore} from 'vuex'
import moment from "moment-timezone";
import FooterComponent from "@/components/layout/FooterComponent.vue";
import http from "@/http";
import {ErrorHandler, errorMessage} from "@/helpers";

const store = useStore()
const state = reactive({
    loader: false,
    mount: false
})
const LoaderState = computed(() => {
    return {
        show: store.state.Loader.show,
        transparent: store.state.Loader.transparent
    }
})
const HasPageConfig = computed(() => {
    return store.getters["Config/GetPageData"]
})
onMounted(async () => {
    moment.tz.setDefault("Asia/Yerevan");
    if (!HasPageConfig?.value?.pages) {
        state.loader = true
        const {data, status} = await getConfigs()
        if (status === 200 && data) {
            store.commit('Config/setYearsList', data.years.map((i) => {
                return {label: i.year, link: `/year/${i.year}?page=1`}
            }))
            store.commit('Config/setPageConfig', data.pages)
            store.commit('Config/setCdnUrl', data.CDN_URL)
            state.loader = false
        }
    }
    state.mount = true
})
const getConfigs = async () => {
    try {
        const res = await http.get('/config')
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}

</script>
