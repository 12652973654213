<template>
    <div class="page-wrapper">
        <h1>Photographers</h1>

        <div class="photographer-list">
            <template v-for="(item,index) in state.StaffList" :key="index">
                <PhotographerItem :PhotographerItem="item"/>
            </template>
        </div>
        <template v-if="state.CooperatorList.length">
            <h2>Cooperators</h2>
            <div class="photographer-list">
                <template v-for="(item,index) in state.CooperatorList" :key="index">
                    <PhotographerItem :image="false" :PhotographerItem="item"/>
                </template>
            </div>
        </template>

    </div>
</template>
<script setup>
import {onMounted, reactive} from "vue";
import {ErrorHandler, errorMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import PhotographerItem from "@/components/pages/photographers/PhotographerItem";
import {SetMetaTags} from "@/helpers/setMetaTags";

const state = reactive({
    StaffList: [],
    CooperatorList: []
})

onMounted(() => {
    SetMetaTags('/photographer', window.location.href)
    PhotographerList().then(payload => {
        let {data, status} = payload
        if (status === 200 && data) {
            state.StaffList = data.filter(item => item.type === 'Staff')
            state.CooperatorList = data.filter(item => item.type === 'Cooperator')
            ToggleLoader(false, false)
        }
    })
})

const PhotographerList = async () => {
    ToggleLoader(true, false)
    try {
        const res = await http.get('/photographers')
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}
</script>
