<template>
    <header ref="headRef">
        <div class="header-wrapper">
            <router-link to="/" class="logo">
                <Logo/>
            </router-link>
            <template v-if="isMobile">
                <div class="wrap">
                    <template v-if="isTablet">
                        <div class="filter-box">
                            <a-input-search
                                v-model:value="state.text"
                                placeholder="Search by text"
                                @search="handleSearch"
                            />
                            <a-date-picker
                                :allow-clear="false"
                                v-model:value="state.date"
                                :disabled-date="disabledDate"
                                @change="handleDate"
                            />
                        </div>
                    </template>
                    <div class="menu-btn" @click="state.showDrawer = true">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </template>
            <template v-else>
                <NavigationComponent/>
                <div class="filter-box">
                    <a-input-search
                        v-model:value="state.text"
                        placeholder="Search by text"
                        @search="handleSearch"
                    />
                    <a-date-picker
                        :allow-clear="false"
                        v-model:value="state.date"
                        :disabled-date="disabledDate"
                        @change="handleDate"
                    />
                </div>
            </template>
            <a-drawer
                v-model:visible="state.showDrawer"
                class="ant-drawer-patient"
                placement="right"
                :closable="false"
                :destroy-on-close="true"
                width="300"
            >
                <div class="header">
                    <button
                        type="button"
                        @click="handleClose"
                        aria-label="Close"
                        class="ant-drawer-close"
                    >
                        <span></span>
                        <span></span>
                    </button>
                </div>

                <div class="content">
                    <NavigationComponent v-on:click="state.showDrawer = false"/>
                    <template v-if="isMobileOnly">
                        <div class="filter-box">
                            <a-input-search
                                v-model:value="state.text"
                                placeholder="Search by text"
                                @search="handleSearch"
                            />
                            <a-date-picker
                                :allow-clear="false"
                                v-model:value="state.date"
                                :disabled-date="disabledDate"
                                @change="handleDate"
                            />
                        </div>
                    </template>
                </div>
            </a-drawer>
            <template v-if="ENV !== 'production'">
                <div class="env-label">{{ ENV }}</div>
            </template>
        </div>
    </header>
</template>

<script setup>
import NavigationComponent from "@/components/partials/NavigationComponent.vue";
import Logo from '@/assets/icons/PhotoLurLogo.vue'
import router from "@/router";
import {computed, reactive, watch} from "vue";
import dayjs from "dayjs";
import {isMobile, isMobileOnly, isTablet} from "mobile-device-detect";
import store from "@/store";

const ENV = process.env.VUE_APP_NODE_ENV

const state = reactive({
    text: '',
    date: '',
    showDrawer: false
})
const SearchByText = computed(() => {
    return store.state.Search.text
})
const SearchByDate = computed(() => {
    return store.state.Search.date
})

watch(SearchByText, (data) => {
    state.text = data
})

watch(SearchByDate, (data) => {
    if (data === '') state.date = ''
    if (data) {
        state.date = dayjs(data)
    }
})
const handleSearch = async (value) => {
    if (!value) return
    state.showDrawer = false
    state.date = undefined
    await router.push({path: '/search', query: {text: value, page: 1}})
}
const handleDate = async (value) => {
    state.text = ''
    state.showDrawer = false
    await router.push({path: '/search', query: {date: dayjs(value).format('YYYY-MM-DD'), page: 1}})
}
const disabledDate = current => {
    return current && current > dayjs().endOf('day');
};

const handleClose = () => {
    state.showDrawer = false
}
</script>
