/* eslint-disable no-template-curly-in-string */
/* eslint-disable */
import store from "@/store";
import {computed, toRaw} from "vue";

const pagesMetaData = computed(() => {
    return store.getters["Config/GetPageData"]
})

export function SetMetaTags(slug, url, data = null) {
    let MetaData = toRaw(pagesMetaData?.value?.filter(item => item.slug === slug)[0]?.meta_data)
    if (document.querySelector(`meta[property="og:url"]`)) {
        document.querySelector(`meta[property="og:url"]`).remove()
    }
    let meta = document.createElement('meta');
    meta.setAttribute('property', 'og:url')
    meta.content = url
    document.getElementsByTagName('head')[0].appendChild(meta);
    if (slug && MetaData) {
        if (document.querySelector(`meta[name="description"]`)) {
            document.querySelector(`meta[name="description"]`).remove()
        }
        document.title = MetaData['og:title']
        let meta = document.createElement('meta');
        meta.setAttribute('name', 'description')
        meta.content = MetaData['og:description']
        document.getElementsByTagName('head')[0].appendChild(meta);
        Object.keys(MetaData).map(key => {
            if (document.querySelector(`meta[property="${key}"]`)) {
                document.querySelector(`meta[property="${key}"]`).remove()
            }
            let meta = document.createElement('meta');
            meta.setAttribute('property', key)
            meta.content = MetaData[key];
            document.getElementsByTagName('head')[0].appendChild(meta);
        })
    } else {
        if (document.querySelector(`meta[name="description"]`)) {
            document.querySelector(`meta[name="description"]`).remove()
        }
        document.title = data['og:title']
        let meta = document.createElement('meta');
        meta.setAttribute('name', 'description')
        meta.content = data['og:description']
        document.getElementsByTagName('head')[0].appendChild(meta);
        Object.keys(data).map(key => {
            if (document.querySelector(`meta[property="${key}"]`)) {
                document.querySelector(`meta[property="${key}"]`).remove()
            }
            let meta = document.createElement('meta');
            meta.setAttribute('property', key)
            meta.content = data[key];
            document.getElementsByTagName('head')[0].appendChild(meta);
        })
    }
}
