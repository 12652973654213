import axios from "axios";

let apiUrl = process.env.VUE_APP_API;
let NODE_ENV = process.env.VUE_APP_NODE_ENV

if (NODE_ENV === "production" || NODE_ENV === "staging" || NODE_ENV === "local") {
    apiUrl = apiUrl + '/front-end';
}

if (NODE_ENV === "development") {
    apiUrl = '/front-end';
}
const instance = axios.create({
    baseURL: apiUrl,
    timeout: 1000000,
});

export default instance
