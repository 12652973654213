import {createRouter, createWebHistory} from "vue-router";
import HomeComponent from "@/components/pages/home/HomeComponent.vue";
import AboutUsComponent from "@/components/pages/about/AboutUsComponent.vue";
import ContactUsComponent from "@/components/pages/contact/ContactUsComponent.vue";
import SearchComponent from "@/components/pages/search/SearchComponent.vue";
import PhotoSetComponent from "@/components/pages/photoset/PhotoSetComponent.vue";
import PhotographerList from "@/components/pages/photographers/PhotographerList.vue";
import PhotographerSingle from "@/components/pages/photographers/PhotographerSingle.vue";
import YearComponent from "@/components/pages/YearComponent.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeComponent,
    },
    {
        path: '/photographer',
        name: "photographer_list",
        component: PhotographerList,
        menu_name: 'Photographers',
        use_in_menu: true
    },
    {
        path: '/photographer/:PhotographerID',
        name: "photographer_single",
        component: PhotographerSingle,
        beforeEnter: (to, from, next) => {
            const {PhotographerID} = to.params
            if (!/^\d+$/.test(PhotographerID)) return next('/photographer')
            return next()
        }
    },
    {
        path: "/photoset/:PhotoSetID",
        name: "photo_set",
        component: PhotoSetComponent,
    },
    {
        path: "/year/:Year",
        name: "year_component",
        component: YearComponent,
        beforeEnter: (to, from, next) => {
            const {Year} = to.params
            if (!/^\d{4}$/.test(Year)) return next('/')
            return next()
        }
    },
    {
        path: "/search",
        name: "search",
        component: SearchComponent,
    },
    {
        path: "/about-us",
        name: "about_us",
        component: AboutUsComponent,
        menu_name: 'About Us',
        use_in_menu: true
    },
    {
        path: "/contact",
        name: "contact_us",
        component: ContactUsComponent,
        menu_name: 'Contact Us',
        use_in_menu: true
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    }
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active"
});

export default router;
