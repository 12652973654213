<template>
    <div class="page-wrapper page-wrapper-full">
        <div class="page-info">
            <h1>Search results for: {{ Year }}</h1>
            <template v-if="!state.PhotoList.length">
                <div class="page-paragraph">
                    Your search returned no results.
                </div>
            </template>
        </div>
        <div class="photo-list">
            <template v-for="(item,index) in state.PhotoList" :key="index">
                <PhotoItem :PhotoItem="item" :modal="true" :PhotoIndex="index"/>
            </template>
        </div>
        <PaginationComponent
            :itemCount="state.itemCount"
            :listLength="state.PhotoList.length"
            :maxPageCount="state.pageCount"
            :currentPage="state.currentPage"
            :perPage="state.perPage"
            componentName="year_component"
            :resetCurrentPage="state.resetCurrentPageValue"
        />
    </div>
    <PhotoModal/>
</template>

<script setup>
import {computed, onMounted, onUnmounted, reactive, watch} from "vue";
import {ToggleLoader} from "@/helpers";
import http from "@/http";
import PhotoItem from "@/components/partials/PhotoItem.vue";
import {useRoute} from "vue-router";
import PhotoModal from "@/components/partials/PhotoModal.vue";
import store from "@/store";
import PaginationComponent from "@/components/partials/PaginationComponent.vue";
import {SetMetaTags} from "@/helpers/setMetaTags";
import router from "@/router";

const state = reactive({
    PhotoList: [],
    PhotographerData: {},
    currentPage: 1,
    itemCount: null,
    pageCount: null,
    perPage: 50,
    resetCurrentPageValue: 1,
    year: ''
})

const route = useRoute()
const Year = computed(() => route.params.Year)

onMounted(async () => {
    ToggleLoader(true, false)
    const {page} = checkUrlForQuery()
    if (page) state.currentPage = Number(page)
    const PhotographerImages = await getImagesByYear(state.perPage, state.currentPage)
    if (PhotographerImages?.data && PhotographerImages?.status === 200) {
        SetMetaTags(null, window.location.href, PhotographerImages?.metaData)
        state.PhotoList = PhotographerImages?.data
        state.itemCount = PhotographerImages?.meta.itemCount
        state.pageCount = PhotographerImages?.meta.pageCount
        state.year = Year.value
        store.commit('PhotoModal/setPhotoList', PhotographerImages?.data)
    }
    ToggleLoader(false, false)
})

onUnmounted(() => {
    store.commit('PhotoModal/setPhotoList', [])
    state.PhotoList = []
})
watch(Year, async YearData => {
    if (YearData && Number(state.year) !== Number(YearData)) {
        ToggleLoader(true, true)
        const PhotographerImages = await getImagesByYear(state.perPage)
        if (PhotographerImages?.data && PhotographerImages?.status === 200) {
            SetMetaTags(null, window.location.href, PhotographerImages?.metaData)
            state.PhotoList = PhotographerImages?.data
            state.itemCount = PhotographerImages?.meta.itemCount
            state.pageCount = PhotographerImages?.meta.pageCount
            store.commit('PhotoModal/setPhotoList', PhotographerImages?.data)
            state.resetCurrentPageValue += 1
            state.currentPage = 1
            state.year = YearData
            window.scrollTo(0, 0);
        }
        ToggleLoader(false, false)
    }
})
watch(router.currentRoute, async data => {
    if (data.query.page && (Number(state.year) !==Number(Year.value))) return
    if (data.query.page && Number(data.query.page) !== Number(state.currentPage)) {
        ToggleLoader(true, true)
        if (data.query.page) state.currentPage = Number(data.query.page)
        const PhotographerImages = await getImagesByYear(state.perPage, state.currentPage)

        if (PhotographerImages?.data && PhotographerImages?.status === 200) {
            SetMetaTags(null, window.location.href, PhotographerImages?.metaData)
            state.PhotoList = PhotographerImages?.data
            state.itemCount = PhotographerImages?.meta.itemCount
            state.pageCount = PhotographerImages?.meta.pageCount
            store.commit('PhotoModal/setPhotoList', PhotographerImages?.data)
            window.scrollTo(0, 0);
        }
        ToggleLoader(false, false)
    }
})
const checkUrlForQuery = () => {
    let query = router.currentRoute.value.query
    let {page} = query
    return {page}
}
const getImagesByYear = async (take = 0, page = 1) => {
    let Url = `/photos?page=${page}&take=${take}&year=${Year.value}`
    try {
        const res = await http.get(Url)
        return {
            data: res.data.data,
            meta: res.data.meta,
            metaData: res.data.metadata,
            status: res.status
        }
    } catch (error) {
        return route.replace('/')
    }
}
</script>
