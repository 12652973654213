<template>
    <a-modal
        :visible="ModalVisible"
        width="calc(100% - 40px)"
        wrap-class-name="ant-modal-photo"
        :destroyOnClose="true"
        :footer="null"
        :closable="false"
        :centered="true"
        :onCancel="handleClosePopUp"
    >
        <template #title>
            <button type="button" aria-label="Close" class="ant-modal-close" @click="handleClosePopUp">
                <span class="ant-modal-close-x">
                    <span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon">
                        <svg focusable="false" class="" data-icon="close" width="1em" height="1em" fill="currentColor"
                             aria-hidden="true" viewBox="64 64 896 896">
                            <path
                                d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"/>
                        </svg>
                    </span>
                </span>
            </button>
        </template>

        <div class="file-box">
            <div class="image-box-wrap">
                <LoaderComponent v-if="ModalLoaderState" :transparent="true"/>

                <div
                    v-if="PhotoActiveIndex !== null"
                    class="photo-nav photo-nav-prev"
                >
                    <div
                        @click="handlePrevImage"
                        :class="PhotoActiveIndex === 0 ? 'not-allowed':''"
                    >
                        <PrevIcon/>
                    </div>
                </div>
                <div
                    v-if="PhotoActiveIndex !== null"
                    class="photo-nav photo-nav-next"
                >
                    <div
                        @click="handleNextImage"
                        :class="PhotoActiveIndex === PhotoListLength ? 'not-allowed':''"
                    >
                        <NextIcon/>
                    </div>
                </div>
                <PictureComponent
                    v-if="state.PhotoData?.image"
                    :imageCode="state.PhotoData.image_code"
                    :loader="true"
                    :url="state.PhotoData?.image"
                    :height="state.PhotoData?.image_height"
                    :width="state.PhotoData?.image_width"
                    :alt="state.PhotoData?.description"
                />
            </div>

            <div class="info-box">
                <div class="info-item">
                    {{ moment(state.PhotoData?.shoot_date).format('dddd, MMMM DD YYYY') }}
                </div>
                <div class="info-item">
                    <span>Photographer</span>
                    <router-link :to="'/photographer/'+state.PhotoData?.photographer_id" class="text-decoration-underline">
                        {{ state.PhotoData?.photographer_full_name }}
                    </router-link>
                </div>
                <div class="info-item">
                    <span>Image code</span>
                    <span>{{ state.PhotoData?.image_code }}</span>
                </div>
                <div class="info-item" v-if="state.PhotoData.collection_id !== undefined && Number(PhotoSetID) !== Number(state.PhotoData?.collection_id)">
                    <span>
                        <router-link :to="'/photoset/'+state.PhotoData?.collection_id" class="text-decoration-underline">
                        VIEW COLLECTION
                    </router-link>
                    </span>
                </div>
                <div class="info-item">
                    {{ state.PhotoData?.description }}
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script setup>
import {computed, onMounted, reactive, watch} from "vue";
import store from "@/store";
import PictureComponent from "@/components/partials/PictureComponent.vue";
import moment from "moment";
import PrevIcon from "@/assets/icons/PrevIcon.vue";
import NextIcon from "@/assets/icons/NextIcon.vue";
import router from "@/router";
import http from "@/http";
import {ErrorHandler, errorMessage} from "@/helpers";
import {loadImage} from "@/helpers/ImageHelper";
import {useRoute} from "vue-router";
import LoaderComponent from "@/components/partials/LoaderComponent.vue";

const route = useRoute();
const PhotoSetID = computed(() => route.params.PhotoSetID)
const state = reactive({
    PhotoData: {}
})
const ModalLoaderState = computed(() => {
    return store.state.PhotoModal.showLoader
})
const ModalVisible = computed(() => {
    return store.state.PhotoModal.ModalVisible
})
const PhotoActiveIndex = computed(() => {
    return store.state.PhotoModal.activeIndex
})

const PhotoListLength = computed(() => {
    return store.state.PhotoModal.photoList.length - 1
})

const getPhotoData = computed(() => {
    return store.getters["PhotoModal/GetPhotoInfo"]
})
const CdnUrl = computed(() => {
    return store.getters["Config/GetCdnUrl"]
})
const checkUrlForQuery = () => {
    let query = router.currentRoute.value.query

    let {image: ImageCode} = query
    return ImageCode
}

watch(ModalVisible, (data) => {
    const PhotoId = checkUrlForQuery()
    if (data && !PhotoId) {
        state.PhotoData = getPhotoData.value
    }
})

watch(PhotoActiveIndex, (data) => {
    if (data || data === 0) {
        router.push({query: {...router.currentRoute.value.query, image: getPhotoData.value.image_code}})
    }
})

onMounted(async () => {
    const PhotoId = checkUrlForQuery()
    store.commit('PhotoModal/toggleModalLoader', true)
    if (PhotoId) {
        const PhotoData = await getImageByImageCode(PhotoId)
        if (PhotoData?.data && PhotoData.status === 200) {
            state.PhotoData = PhotoData.data
            store.commit('PhotoModal/setModalVisible', true)
            await loadImage(CdnUrl.value + PhotoData.data.image).then(() => {
                return store.commit('PhotoModal/toggleModalLoader', false)
            })
        }
    }
})

watch(checkUrlForQuery, async (data) => {
    if (data) {
        const PhotoData = await getImageByImageCode(data)
        if (PhotoData?.data && PhotoData.status === 200) {
            state.PhotoData = PhotoData.data
            store.commit('PhotoModal/setModalVisible', true)
            await loadImage(CdnUrl.value + PhotoData.data.image).then(() => {
                return store.commit('PhotoModal/toggleModalLoader', false)
            })
        }
    } else {
        store.commit('PhotoModal/setActiveIndex', null)
        store.commit('PhotoModal/toggleModalLoader', false)
        store.commit('PhotoModal/setModalVisible', false)
    }
})

const getImageByImageCode = async (ImageCode) => {
    try {
        const res = await http.get(`/photo/${ImageCode}`)
        return {
            data: res.data.data,
            metaData: res.data.metaData,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}

const handleClosePopUp = () => {
    const PhotoId = checkUrlForQuery()

    if (PhotoId) {
        const {text, date, page} = router.currentRoute.value.query
        let queryParams = {}
        if (text) {
            queryParams.text = router.currentRoute.value.query.text
        }
        if (date) {
            queryParams.date = router.currentRoute.value.query.date
        }
        if (page) {
            queryParams.page = router.currentRoute.value.query.page
        }
        router.push({query: queryParams})
    }
    store.commit('PhotoModal/setActiveIndex', null)
    store.commit('PhotoModal/setModalVisible', false)
}

const handlePrevImage = () => {
    if (PhotoActiveIndex.value !== 0) {
        store.commit('PhotoModal/toggleModalLoader', true)
        store.commit('PhotoModal/setActiveIndex', PhotoActiveIndex.value - 1)
    }
}
const handleNextImage = () => {
    if (PhotoActiveIndex.value !== PhotoListLength.value) {
        store.commit('PhotoModal/toggleModalLoader', true)
        store.commit('PhotoModal/setActiveIndex', PhotoActiveIndex.value + 1)
    }
}
</script>
