<template>
  <footer>
    <div class="footer-wrapper">
      <router-link to="/" class="logo">
        <Logo/>
      </router-link>
      <NavigationComponent/>
    </div>
    <div class="footer-wrapper footer-wrapper-bordered">
      <div class="title">
        Browse by year
      </div>
      <div class="navigation collection">
        <template v-for="(item,index) in CollectionByYears" :key="index">
          <div>
            <router-link :to="item.link">
              {{ item.label }}
            </router-link>
          </div>
        </template>
      </div>
    </div>
    <div class="footer-wrapper">
      <div class="copy-right">
        All rights reserved © 2002 - {{ dayjs().year() }} Photolure
      </div>
      <div class="social">
        <a href="https://www.facebook.com/PhotolureNewsAgency/" target="_blank">
          <FacebookIcon/>
        </a>
        <a href="https://twitter.com/photolure" target="_blank">
          <TwitterIcon/>
        </a>
        <a href="https://www.instagram.com/photolure/" target="_blank">
          <InstagramIcon/>
        </a>
      </div>
    </div>
    <div class="footer-wrapper">
      <div style="justify-content: center; display: flex; color: #fff; font-size: 12px; width: 100%">The photographic files displayed on the website are safeguarded by RA copyright laws and are the exclusive
        property of Photolur LLC or the Photolur news agency.</div>
      <div style="justify-content: center; display: flex; color: #fff; font-size: 12px; width: 100%">Any unauthorized use is strictly prohibited without obtaining prior permission.</div>
    </div>
  </footer>
</template>

<script setup>
import Logo from '@/assets/icons/PhotoLurLogo.vue'
import NavigationComponent from "@/components/partials/NavigationComponent.vue";
import FacebookIcon from "@/assets/icons/FacebookIcon.vue";
import TwitterIcon from "@/assets/icons/TwitterIcon.vue";
import InstagramIcon from "@/assets/icons/InstagramIcon.vue";
import dayjs from "dayjs";
import {computed} from "vue";
import store from "@/store";

const CollectionByYears = computed(() => {
  return store.getters["Config/GetYearsList"]
})
</script>
