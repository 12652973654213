<template>
    <div class="page-wrapper page-wrapper-full">
        <div class="photographer-info">
            <PictureComponent
                v-if="state.PhotographerData.type === 'Staff'"
                :url="state.PhotographerData.avatar"
                :width="200"
                :height="200"
                :title="state.PhotographerData.first_name+' '+ state.PhotographerData.last_name"
            />
            <div class="text-box">
                <div class="name">
                    {{ state.PhotographerData.first_name + ' ' + state.PhotographerData.last_name }}
                </div>
                <div class="position">
                    {{ state.PhotographerData.position }}
                </div>
                <div v-if="state.PhotographerData.bio" class="bio">
                    {{ state.PhotographerData.bio }}
                </div>
            </div>
        </div>
        <div class="photo-list">
            <template v-if="state.PhotoList.length">
                <template v-for="(item,index) in state.PhotoList" :key="index">
                    <PhotoItem :PhotoItem="item" :modal="true" :PhotoIndex="index"/>
                </template>
            </template>
            <template v-else>
                <div class="page-paragraph">
                    <span>{{ state.PhotographerData.first_name + ' ' + state.PhotographerData.last_name }}</span>
                    doesn't have photos!
                </div>
            </template>
        </div>
        <PaginationComponent
            :itemCount="state.itemCount"
            :listLength="state.PhotoList.length"
            :maxPageCount="state.pageCount"
            :currentPage="state.currentPage"
            :perPage="state.perPage"
            componentName="photographer_single"
            :resetCurrentPage="state.resetCurrentPageValue"
        />
    </div>
    <PhotoModal/>
</template>

<script setup>
import {computed, onMounted, onUnmounted, reactive, watch} from "vue";
import {ErrorHandler, errorMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import PhotoItem from "@/components/partials/PhotoItem.vue";
import {useRoute} from "vue-router";
import PhotoModal from "@/components/partials/PhotoModal.vue";
import store from "@/store";
import PictureComponent from "@/components/partials/PictureComponent.vue";
import PaginationComponent from "@/components/partials/PaginationComponent.vue";
import {SetMetaTags} from "@/helpers/setMetaTags";
import router from "@/router";

const state = reactive({
    PhotoList: [],
    PhotographerData: {},
    currentPage: 1,
    itemCount: null,
    perPage: 50,
    pageCount: null,
    resetCurrentPageValue: 1
})

const route = useRoute()
const PhotographerID = computed(() => route.params.PhotographerID)

onMounted(async () => {
    const {page} = checkUrlForQuery()
    if (page) state.currentPage = Number(page)
    ToggleLoader(true, false)
    const PhotographerData = await getPhotographerData()
    if (PhotographerData?.data && PhotographerData?.status === 200) {
        state.PhotographerData = PhotographerData.data
        SetMetaTags(null, window.location.href, PhotographerData?.metaData)
        const PhotographerImages = await getPhotographerImages(state.perPage, state.currentPage)
        if (PhotographerImages?.data && PhotographerImages?.status === 200) {
            state.PhotoList = PhotographerImages?.data
            state.itemCount = PhotographerImages?.meta.itemCount
            state.pageCount = PhotographerImages?.meta.pageCount
            store.commit('PhotoModal/setPhotoList', PhotographerImages?.data)
        }
    }
    ToggleLoader(false, false)
})
watch(router.currentRoute, async data => {

    if (data.query.page && Number(data.query.page) !== Number(state.currentPage)) {
        ToggleLoader(true, true)
        SetMetaTags('/', window.location.href)
        data.query.page ? state.currentPage = Number(data.query.page) : 1
        const PhotographerImages = await getPhotographerImages(state.perPage, state.currentPage)
        if (PhotographerImages?.data && PhotographerImages?.status === 200) {
            state.PhotoList = PhotographerImages?.data
            state.itemCount = PhotographerImages?.meta.itemCount
            state.pageCount = PhotographerImages?.meta.pageCount
            store.commit('PhotoModal/setPhotoList', PhotographerImages?.data)
            window.scrollTo(0, 0);
            ToggleLoader(false)
        }
    }
})
onUnmounted(() => {
    store.commit('PhotoModal/setPhotoList', [])
    state.PhotoList = []
})
const checkUrlForQuery = () => {
    let query = router.currentRoute.value.query
    let {page} = query
    return {page}
}
const getPhotographerData = async () => {
    try {
        const res = await http.get(`/photographers/${PhotographerID.value}`)
        return {
            data: res.data.data,
            metaData: res.data.metadata,
            status: res.status
        }
    } catch (error) {
        return route.replace('/photographer')
    }
}
const getPhotographerImages = async (take = 0, page = 1) => {
    let Url = `/photos?page=${page}&take=${take}&photographer_id=${PhotographerID.value}`
    try {
        const res = await http.get(Url)
        return {
            data: res.data.data,
            meta: res.data.meta,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}
</script>
