<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.78 99.19">
        <g id="Layer_1-2" data-name="Layer 1-2">
            <path d="M56.18,80.08c-9.26,0-18.53-.06-27.79,.01-7.79,.06-14.18-2.88-19.22-8.51C-1.66,59.47-3.19,30.61,6.15,17.46c3.52-4.96,8.53-6.93,14.48-7.01,7.47-.09,13.87-2.5,20.39-6.29,9.64-5.6,20.58-5.5,30.24,.02,6.22,3.56,12.23,6.21,19.39,6.19,10.06-.02,16.1,5.08,18.68,14.21,3.86,13.72,4.3,27.43-1.91,40.66-4.52,9.64-12.24,14.92-23.44,14.83-9.26-.07-18.53-.01-27.79-.02h0Zm.26-6.41c7.96,0,15.92,.08,23.87-.02,12.17-.15,18.38-4.48,22.05-15.72,3.31-10.12,3.03-20.34,.09-30.55-2.04-7.07-6.66-10.9-14.2-11.41-7.34-.5-13.95-2.49-20.58-6.14-7.72-4.25-16.69-4.13-24.55,.75-4.65,2.89-9.5,4.42-14.83,5.01-15.06,1.66-18.71,5.1-20.3,19.69-.84,7.76-.7,15.5,2.07,22.99,3.87,10.45,10.22,15.12,21.52,15.36,8.28,.18,16.58,.04,24.87,.04h0Z" style="fill: #2cb1e6;"/>
            <circle cx="56.84" cy="42.6" r="17.64" style="fill: #2cb1e6;"/>
            <circle cx="24.04" cy="51.49" r="3.94" style="fill: #2cb1e6;"/>
            <path d="M56.84,67.27c-13.6,0-24.66-11.07-24.66-24.66s11.07-24.66,24.66-24.66,24.66,11.07,24.66,24.66-11.07,24.66-24.66,24.66Zm0-44.3c-10.83,0-19.64,8.81-19.64,19.64s8.81,19.64,19.64,19.64,19.64-8.81,19.64-19.64-8.81-19.64-19.64-19.64Z" style="fill: #2cb1e6;"/>
        </g>
        <g>
            <path d="M272.06,92.27v-8.49c0-3.33-1.21-5-3.63-5-.64,0-1.23,.13-1.79,.38s-1.02,.61-1.42,1.06-.71,.98-.94,1.6-.34,1.29-.34,2.01v8.44h-1.84v-14.74h1.84v2.56h.06c1.09-1.94,2.74-2.91,4.94-2.91,1.61,0,2.84,.53,3.69,1.57s1.27,2.56,1.27,4.54v8.98h-1.84Z" style="fill: #1f1a18;"/>
            <path d="M277.44,85.22c.01,.9,.13,1.71,.36,2.43s.56,1.33,1,1.83,.96,.88,1.58,1.14c.62,.26,1.32,.4,2.1,.4,1.66,0,3.23-.58,4.71-1.74v1.82c-1.41,1.01-3.12,1.51-5.12,1.51-.99,0-1.88-.17-2.69-.52s-1.49-.85-2.06-1.5c-.56-.65-1-1.47-1.31-2.43s-.46-2.05-.46-3.28c0-1.11,.17-2.13,.5-3.07,.34-.94,.8-1.75,1.39-2.44,.6-.68,1.3-1.22,2.12-1.61s1.71-.58,2.68-.58,1.79,.17,2.52,.5,1.34,.81,1.83,1.42,.87,1.36,1.13,2.24c.26,.88,.39,1.87,.39,2.97v.92h-10.66Zm8.76-1.61c-.03-1.53-.39-2.73-1.09-3.57s-1.68-1.27-2.92-1.27c-.62,0-1.2,.11-1.74,.33-.53,.22-1,.54-1.41,.96-.4,.42-.73,.92-1,1.53-.26,.6-.45,1.27-.55,2.02h8.71Z" style="fill: #1f1a18;"/>
            <path d="M303.26,92.27h-1.84l-3.12-10.83c-.1-.34-.17-.77-.23-1.31h-.06c-.02,.14-.05,.32-.09,.53s-.11,.46-.21,.75l-3.44,10.86h-1.8l-4.46-14.74h1.97l3.24,11.43c.1,.34,.17,.76,.2,1.28h.12c.02-.17,.05-.37,.09-.59,.04-.22,.1-.45,.18-.7l3.57-11.41h1.53l3.2,11.45c.09,.32,.16,.75,.21,1.28h.12c-.01-.17,.01-.35,.06-.57,.05-.21,.11-.45,.18-.71l3.24-11.45h1.86l-4.51,14.74h-.01Z" style="fill: #1f1a18;"/>
            <path d="M307.97,89.62c.28,.22,.58,.42,.91,.59,.32,.17,.66,.32,1.01,.45,.35,.12,.7,.22,1.05,.28s.68,.09,1,.09c2.19,0,3.28-.82,3.28-2.46,0-.37-.08-.7-.23-.97s-.37-.53-.66-.75c-.29-.23-.64-.43-1.03-.62-.4-.19-.86-.39-1.37-.61-.68-.28-1.27-.56-1.75-.85s-.89-.6-1.21-.94c-.32-.34-.55-.72-.7-1.12s-.23-.87-.23-1.39c0-.65,.14-1.24,.43-1.74,.28-.51,.67-.94,1.14-1.3,.48-.35,1.03-.62,1.66-.81s1.29-.28,1.98-.28c1.28,0,2.39,.24,3.34,.72v1.94c-1.11-.71-2.29-1.06-3.57-1.06-.46,0-.88,.06-1.26,.17s-.7,.28-.97,.49-.48,.46-.62,.76c-.15,.29-.23,.62-.23,.97s.06,.68,.17,.96c.12,.28,.29,.52,.54,.74s.56,.43,.95,.64c.39,.2,.86,.42,1.42,.65,.71,.29,1.33,.57,1.84,.86,.52,.28,.95,.59,1.28,.92s.59,.7,.75,1.11,.24,.88,.24,1.42c0,.66-.13,1.25-.41,1.77-.28,.52-.65,.95-1.14,1.31s-1.05,.62-1.71,.81-1.36,.28-2.13,.28c-1.49,0-2.74-.31-3.76-.94v-2.06h-.02Z" style="fill: #1f1a18;"/>
            <path d="M333.81,92.27v-2.62h-.06c-.48,.94-1.14,1.67-1.96,2.19-.83,.52-1.77,.78-2.81,.78-.71,0-1.35-.1-1.92-.3s-1.06-.49-1.46-.86c-.4-.37-.71-.81-.92-1.33-.21-.52-.32-1.09-.32-1.71,0-1.33,.42-2.4,1.24-3.19,.83-.79,2.07-1.31,3.74-1.55l4.48-.65c0-2.83-1.1-4.25-3.3-4.25-1.72,0-3.31,.63-4.78,1.88v-2.02c.31-.22,.65-.42,1.05-.6s.8-.33,1.23-.46c.43-.13,.87-.23,1.33-.3,.46-.07,.9-.11,1.33-.11,1.64,0,2.88,.46,3.72,1.38,.84,.92,1.26,2.29,1.26,4.11v9.59h-1.85Zm-3.8-7.14c-.66,.1-1.23,.21-1.7,.36-.47,.14-.86,.34-1.17,.58s-.53,.55-.68,.9c-.14,.36-.21,.81-.21,1.33,0,.39,.07,.76,.21,1.09,.13,.34,.34,.63,.61,.87,.26,.24,.59,.43,.97,.57,.38,.13,.81,.2,1.28,.2,.65,0,1.25-.12,1.8-.37s1.02-.59,1.42-1.04,.71-.97,.94-1.58c.23-.61,.34-1.28,.34-2v-1.46l-3.8,.53h0Z" style="fill: #1f1a18;"/>
            <path d="M350.5,91.13c0,2.73-.62,4.76-1.86,6.08-1.24,1.32-3.16,1.98-5.75,1.98-1.61,0-3.14-.36-4.58-1.08v-1.87c1.6,.89,3.13,1.34,4.58,1.34,3.85,0,5.77-2.04,5.77-6.1v-1.77h-.06c-1.13,1.94-2.86,2.91-5.18,2.91-.91,0-1.74-.17-2.49-.5-.75-.34-1.4-.82-1.94-1.46s-.95-1.41-1.25-2.31-.45-1.93-.45-3.08c0-1.21,.16-2.31,.48-3.3,.32-1,.76-1.85,1.35-2.56,.59-.71,1.29-1.26,2.12-1.65s1.76-.58,2.8-.58c2.08,0,3.6,.84,4.56,2.54h.06v-2.19h1.84v13.6h.01Zm-1.84-7.83c0-.61-.11-1.2-.33-1.74-.22-.55-.53-1.03-.92-1.44-.39-.42-.86-.74-1.4-.98-.54-.24-1.14-.37-1.79-.37-.77,0-1.46,.15-2.08,.45-.62,.3-1.14,.72-1.58,1.27-.44,.54-.77,1.21-1.01,1.99s-.35,1.66-.35,2.64c0,.9,.11,1.72,.33,2.45s.53,1.35,.94,1.86c.4,.51,.89,.9,1.46,1.18s1.2,.42,1.91,.42,1.39-.13,1.99-.4c.59-.26,1.1-.63,1.53-1.09,.42-.46,.75-1.01,.98-1.64,.23-.63,.34-1.31,.34-2.04v-2.55h0Z" style="fill: #1f1a18;"/>
            <path d="M354.36,85.22c.01,.9,.13,1.71,.36,2.43s.56,1.33,1,1.83,.96,.88,1.58,1.14,1.32,.4,2.1,.4c1.66,0,3.23-.58,4.71-1.74v1.82c-1.41,1.01-3.12,1.51-5.12,1.51-.99,0-1.88-.17-2.69-.52s-1.49-.85-2.06-1.5c-.56-.65-1-1.47-1.31-2.43s-.46-2.05-.46-3.28c0-1.11,.17-2.13,.5-3.07,.34-.94,.8-1.75,1.39-2.44,.6-.68,1.3-1.22,2.12-1.61s1.71-.58,2.68-.58,1.79,.17,2.52,.5,1.34,.81,1.83,1.42,.87,1.36,1.13,2.24c.26,.88,.39,1.87,.39,2.97v.92h-10.66Zm8.76-1.61c-.03-1.53-.39-2.73-1.09-3.57s-1.68-1.27-2.92-1.27c-.62,0-1.2,.11-1.74,.33-.53,.22-1,.54-1.41,.96-.4,.42-.73,.92-1,1.53-.26,.6-.45,1.27-.55,2.02h8.71Z" style="fill: #1f1a18;"/>
            <path d="M376.81,92.27v-8.49c0-3.33-1.21-5-3.63-5-.64,0-1.24,.13-1.79,.38s-1.02,.61-1.42,1.06-.71,.98-.94,1.6c-.23,.62-.34,1.29-.34,2.01v8.44h-1.84v-14.74h1.84v2.56h.06c1.09-1.94,2.74-2.91,4.94-2.91,1.61,0,2.84,.53,3.69,1.57s1.27,2.56,1.27,4.54v8.98h-1.84Z" style="fill: #1f1a18;"/>
            <path d="M391.12,91.61c-1.09,.66-2.42,1-3.97,1.02-1.06,.01-2.01-.17-2.85-.55-.84-.37-1.57-.89-2.16-1.56-.59-.67-1.05-1.46-1.37-2.36s-.48-1.9-.48-2.96,.17-2.15,.5-3.12c.34-.97,.83-1.82,1.47-2.55,.64-.72,1.43-1.3,2.36-1.72,.93-.42,2-.64,3.2-.64s2.33,.24,3.34,.71v1.96c-1.08-.71-2.24-1.06-3.48-1.06-.87,0-1.65,.17-2.33,.5s-1.25,.78-1.72,1.35-.82,1.24-1.06,2-.37,1.57-.37,2.43c0,.79,.1,1.54,.31,2.26s.52,1.36,.95,1.91,.97,.99,1.62,1.32,1.42,.49,2.3,.49c1.38,0,2.63-.42,3.74-1.24v1.82h0Z" style="fill: #1f1a18;"/>
            <path d="M397.17,94.94c-.57,1.44-1.24,2.51-1.99,3.2s-1.66,1.05-2.72,1.05c-.53,0-.97-.06-1.34-.19v-1.73c.21,.08,.43,.13,.64,.17s.43,.06,.65,.06c.61,0,1.13-.19,1.58-.57,.45-.38,.83-.95,1.16-1.72l1.28-3.02-5.67-14.67h2.04l4.22,11.68c.06,.15,.12,.33,.17,.53,.06,.2,.1,.39,.15,.58h.07c.05-.17,.09-.33,.13-.5,.04-.17,.1-.35,.18-.56l4.48-11.73h1.94l-6.98,17.42h0Z" style="fill: #1f1a18;"/>
        </g>
        <g>
            <path d="M127.74,16.77h-13.42v.94c2.37,8.57,3.66,17.58,3.66,26.89s-1.29,18.33-3.66,26.89v.94h10.44v-21.84h2.98c4.46,0,7.88-1.24,10.16-3.69,2.26-2.43,3.41-5.99,3.41-10.57v-5.29c0-4.59-1.15-8.14-3.41-10.57-2.28-2.45-5.7-3.69-10.16-3.69h0Zm5.08,17.82c0,1.55-.27,2.67-.78,3.24-.5,.55-1.27,.82-2.35,.82h-4.93v-11.29h4.93c1.09,0,1.86,.27,2.36,.82,.51,.56,.78,1.68,.78,3.23v3.18h0Z" style="fill: #1f1a18;"/>
            <polygon points="164.75 38.62 157.72 38.62 157.72 16.77 147.28 16.77 147.28 72.43 157.72 72.43 157.72 48.29 164.75 48.29 164.75 72.43 175.2 72.43 175.2 16.77 164.75 16.77 164.75 38.62" style="fill: #1f1a18;"/>
            <path d="M196.04,16c-4.41,0-7.86,1.3-10.24,3.85-2.37,2.53-3.56,6.11-3.56,10.65v28.21c0,4.54,1.2,8.12,3.56,10.65,2.38,2.55,5.83,3.85,10.24,3.85s7.85-1.3,10.24-3.85c2.37-2.54,3.56-6.12,3.56-10.65V30.5c0-4.53-1.2-8.11-3.56-10.65-2.39-2.55-5.83-3.85-10.24-3.85Zm3.36,43.24c0,3.8-1.8,4.29-3.36,4.29s-3.37-.49-3.37-4.29V29.96c0-3.8,1.8-4.29,3.37-4.29s3.36,.49,3.36,4.29v29.28h0Z" style="fill: #1f1a18;"/>
            <polygon points="214.35 26.45 223.17 26.45 223.17 72.43 233.61 72.43 233.61 26.45 242.43 26.45 242.43 16.77 214.35 16.77 214.35 26.45" style="fill: #1f1a18;"/>
            <path d="M260.74,16c-4.41,0-7.86,1.3-10.24,3.85-2.37,2.53-3.56,6.11-3.56,10.65v28.21c0,4.54,1.2,8.12,3.56,10.65,2.38,2.55,5.83,3.85,10.24,3.85s7.86-1.3,10.24-3.85c2.37-2.53,3.56-6.11,3.56-10.65V30.5c0-4.53-1.2-8.11-3.56-10.65-2.38-2.55-5.83-3.85-10.24-3.85Zm3.36,43.24c0,3.8-1.8,4.29-3.36,4.29s-3.37-.49-3.37-4.29V29.96c0-3.8,1.8-4.29,3.37-4.29s3.36,.49,3.36,4.29v29.28h0Z" style="fill: #1f1a18;"/>
            <polygon points="292.02 16.77 281.58 16.77 281.58 72.43 305.89 72.43 305.89 62.76 292.02 62.76 292.02 16.77" style="fill: #1f1a18;"/>
            <path d="M328.03,59.32c0,1.57-.29,2.69-.85,3.31-.55,.61-1.35,.9-2.44,.9s-1.89-.29-2.44-.9c-.56-.62-.85-1.74-.85-3.31V16.77h-10.44V58.71c0,4.59,1.17,8.18,3.48,10.68,2.33,2.53,5.73,3.82,10.09,3.82s7.76-1.28,10.09-3.81c2.32-2.51,3.48-6.1,3.48-10.69V16.77h-10.14V59.32h.01Z" style="fill: #1f1a18;"/>
            <path d="M368.13,43.98c2.9-1.97,4.37-5.52,4.37-10.57v-3.3c0-4.44-1.1-7.81-3.28-10.02s-5.62-3.33-10.22-3.33h-13.5v55.67h10.44v-22.99h1.91c1.61,0,2.73,.37,3.32,1.09,.44,.54,.96,1.75,.96,4.5v17.37l10.44,.08v-16.99c0-3.3-.45-5.99-1.35-8-.68-1.53-1.72-2.71-3.1-3.51h0Zm-6.07-8.88c0,1.77-.33,3-.98,3.67-.64,.66-1.68,1-3.07,1h-2.06v-13.32h2.83c1.17,0,1.97,.3,2.47,.92,.53,.68,.81,1.92,.81,3.59v4.14Z" style="fill: #1f1a18;"/>
            <polygon points="404.78 26.45 404.78 16.77 379.77 16.77 379.77 72.43 404.78 72.43 404.78 62.76 390.22 62.76 390.22 48.29 401.79 48.29 401.79 38.62 390.22 38.62 390.22 26.45 404.78 26.45" style="fill: #1f1a18;"/>
        </g>
    </svg>
</template>
